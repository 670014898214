export const card_data=[
    {
        "title":"Abacus",
        "imageCode":1,
        "imageInvalid":"couldn't find the image",
        "description":"Helps the Children to develop fine mathematical & problem-solving skills that can be beneficial for their overall academic & personal growth in a fun and engaging way",
        "tags":[],//["Walkers","Joggers","Sprinters"],
        "css":"w-full h-3/5",
        "headCount":21,
        "gradCount":16,
        "rating":4.7,
    },
    {        
        "title":"Rubik's Cube",
        "imageCode":2,
        "imageInvalid":"couldn't find the image",
        "description":"Learn to solve various types of Rubik's Cubes which will improve the dexterity, motor skills, logical thinking & analytical abilities",
        "tags":[],//["2x2 Cube","3x3 Cube","4x4 Cube"],
        "css":"w-full h-3/5",
        "headCount":16,
        "gradCount":9,
        "rating":4.5,
    },
    {
        "title":"Vedic Maths",
        "imageCode":5,
        "imageInvalid":"couldn't find the image",
        "description":"Offers an alternative, more intuitive approach to mathematical problem-solving that can make it easier and more enjoyable for children to improve their mental math abilities.",
        "tags":[],//["Level 1","Level 2"],
        "css":"w-full h-3/5",
        "headCount":18,
        "gradCount":10,
        "rating":4.7,
    },
    {
        "title":"Calligraphy",
        "imageCode":3,
        "imageInvalid":"couldn't find the image",
        "description":"A Fun and engaging way for children to develop their Artistic abilities, Patience, Persistence and Mindfulness",
        "tags":[],
        "css":"w-full h-3/5",
        "headCount":9,
        "gradCount":6,
        "rating":4.4,
    },
    {
        "title":"Hand Writing",
        "imageCode":4,
        "imageInvalid":"couldn't find the image",
        "description":" With regular practice & guidance on proper letter formation, spacing and penmanship techniques, it can improve the legibility and neatness of the Hand-Writing",
        "tags":[],
        "css":"w-full h-3/5",
        "headCount":12,
        "gradCount":7,
        "rating":4.6,
    },
    {
        "title":"Coming Soon",
        "imageCode":4,
        "imageInvalid":"",
        "description":"We will be including new courses gradually in this space. Look out for new updates",
        "tags":[],
        "css":"w-full h-3/5",
    },   
]